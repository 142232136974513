<template>
  <div>
    <div class="radio-checkbox">
        <div>
          <p>Radio</p>
          <div class="checkboxList">
            <el-radio v-model="radio">Option</el-radio>
            <el-radio disabled v-model="radio1">Option A</el-radio>
            <el-radio v-model="radio2">Option A</el-radio>
          </div>
        </div>
        <div>
          <p>Chexbox</p>
          <div class="checkboxList">
            <p><el-checkbox v-model="checked">Option</el-checkbox></p>
            <p><el-checkbox type="primary" v-model="checked1">Option</el-checkbox></p>
            <p><el-checkbox v-model="checked2" >Option</el-checkbox></p>
            <p><el-checkbox v-model="checked3" disabled>Option</el-checkbox></p>
            <p><el-checkbox disabled v-model="checked">Option</el-checkbox></p>
            <p>Chexbox Group</p>
            <div>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Check all</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" style="display: flex">
                  <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
const cityOptions = ['Option1', 'Option2', 'Option3', 'Option4'];
export default {
  name: 'CheckboxRadio',
  components : {
  },
  data() {
    return {
      radio: false,
      radio1: false,
      radio2: false,
      checked: false,
      checked1: false,
      checked2: true,
      checked3: true,
      checkAll: false,
      isIndeterminate: true,
      checkedCities: ['Option1', 'Option2'],
      cities: cityOptions,
    }
  },
  methods: {
    handleCheckAllChange(val) {
        this.checkedCities = val ? cityOptions : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      }
  }
}
</script>

<style lang="scss" scoped>
.radio-checkbox {
  width: 60vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.checkboxList {
  padding-top: 32px;
  display: grid;
  gap: 32px;
}
</style>
